
import './Loading.css'


function Loader() {

  return (
    <>
   <div className='loadingDiv'><div id="loading"></div></div> 
    </>
  )
}

export default Loader