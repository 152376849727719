import React from 'react';
import Content from './Content/Content';
import './Dashboard.css';


const Dashboard = () => {
    
    return (
        <div>
           
            <Content></Content>
           
        </div>
    );
};

export default Dashboard;